$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/bourbon/core/_bourbon.scss";

/*---- NUMBER OF SLIDE CONFIGURATION ----*/
$num-of-slide: 7;

.wrapper {
    width: 55em;
    margin: 1em auto;
    position: relative;
    border-radius: 5px;
    box-shadow: 1px 1px 4px #666;
    background: #fff;
}

.all-width {
  width: 100%;
  margin-bottom: 3em;
}

.center-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slider-wrapper img {
    display: none;
    height: 40em;
    width: 100%;
}

.slider-wrapper img.visible {
    display: block;
}

/*---- TABLET ----*/
@media only screen and (max-width: 850px) and (min-width: 450px) {
    .slider-wrapper {
        border-radius: 0;
    }
}

/*---- MOBILE----*/
@media only screen and (max-width: 450px) {
    .slider-wrapper {
        border-radius: 0;
    }

    .slider-wrapper .info {
        opacity: 0;
    }
}

@media only screen and (min-width: 850px) {
    body {
        padding: 0 80px;
    }
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  /* Make the masthead heading the same height as the navigation */
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
  }
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    font-size: 21px;
    padding: 14px 24px;
  }
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
  p + h4 {
    margin-top: 28px;
  }
}

/* Responsive: Portrait tablets and up */


@media screen and (max-width: 780px) {
.wrapper {
    width: 90% !important;
}
.slider-wrapper img {
    height: auto !important;
}
}

@media screen and (min-width: 780px) {
  .container {
    max-width: 730px;
  }

  .centered {
    width: 55em;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}
